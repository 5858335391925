import NavBar from "../components/NavBar/navBar";
import CreativeDirectionContent from "../components/CDContent/cdContent";
import React from "react";

const CreativeDirection = () => {
  return (
    <div className="App">
      <NavBar />
      <CreativeDirectionContent />
    </div>
  );
};

export default CreativeDirection;
