import NavBar from "../components/NavBar/navBar";
import Landing from "../components/Landing/landing";
import React from "react";

const Home = () => {
  return (
    <div className="App">
      <NavBar />
      <Landing />
    </div>
  );
};

export default Home;
