import NavBar from '../components/NavBar/navBar';
import AboutContent from '../components/AboutContent/aboutContent';
import React from 'react';
const AboutMe = () => {
  return (
    <div className="App">
      <NavBar/> 
      <AboutContent/>
    </div>
  );
};

export default AboutMe;
