import React from "react";
import NavBar from "../components/NavBar/navBar";
import SoftwareContent from "../components/SoftwareContent/softwareContent";

const SoftwareDevelopment = () => {
  return (
    <div className="App">
      <NavBar />
      <SoftwareContent />
    </div>
  );
};

export default SoftwareDevelopment;
