import React from "react";
import GraphicDesignContent from "../components/DesignContent/designContent";
import NavBar from "../components/NavBar/navBar";

const GraphicDesign = () => {
  return (
    <div className="App">
      <NavBar />
      <GraphicDesignContent />
    </div>
  );
};

export default GraphicDesign;
